@import '@popsure/dirty-swan/dist/colors';

.container {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

@keyframes appear-in {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.button {
  background-color: $ds-white;
}

.img {
  animation-name: appear-in;
  animation-duration: 0.3s;
  animation-delay: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}
